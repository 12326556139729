import React, {useContext, useState} from 'react'
import {
    Hamburger,
    HamburgerLine,
    Items,
    Logo,
    LogoutContainer,
    MobileItems,
    Nav,
    NavBar,
    NavLink
} from './Navigation.styled'
import {Col, Container} from 'react-bootstrap';
import {User} from '../../context/userContext';
import {routes} from '../../routes/Routes';
import {useNavigate} from 'react-router';
import useAdmin from '../../hooks/useAdmin';

const Navigation = () => {

    let [user, setUser] = useContext(User);
    let [menuActive, setMenuActive] = useState(false);
    const navigator = useNavigate();
    let isTeacher = useAdmin();

    const handleLogout = (event) => {
        event.preventDefault();

        localStorage.removeItem('reportData');

        setUser({
            logged: false,
            token: null,
            data: {}
        })
        navigator('/login');
    }

    const handleOnLinkClick = () => {
        setMenuActive(false);
    }

    const generateMenu = () => {
        return routes.map((route, index) => {
            if (!user.logged) return "";
            if (route.adminVisibility === false && isTeacher) return "";

            if (route.studentVisibility === false && !isTeacher) return "";

            return route.nav ? <NavLink key={index} icon={route.icon} to={route.path}
                                        onClick={handleOnLinkClick}>{route.name}</NavLink> : null;
        })
    }

    const handleLogoClick = () => {
        setMenuActive(false);
        navigator('/');
    }


    const toogleMenu = () => {
        setMenuActive(prev => !prev);
    }

    return (
        <NavBar>
            <Col>
                <Container>
                    <Nav>
                        <Logo className="pointer" src="/logo_white.png" onClick={handleLogoClick}/>
                        <Items className="d-none d-md-flex">
                            {generateMenu()}
                        </Items>
                        <LogoutContainer>
                            <Hamburger onClick={toogleMenu} className='flex-shrink-0'>
                                <HamburgerLine/>
                                <HamburgerLine/>
                                <HamburgerLine/>
                            </Hamburger>
                            {/*<Button variant="outline-light" onClick={handleLogout}>Wyloguj</Button>*/}
                        </LogoutContainer>


                        <MobileItems className="d-flex d-md-none" active={menuActive ? 1 : 0}>
                            {generateMenu()}
                        </MobileItems>
                    </Nav>
                </Container>
            </Col>
        </NavBar>
    )
}

export default Navigation