import React, {useContext} from 'react'
import {Card, Col} from 'react-bootstrap'
import {Cards, Name} from './Main.styled'
import {User} from '../../context/userContext'
import {useNavigate} from 'react-router'
import useAdmin from '../../hooks/useAdmin'

const Main = () => {
    const [user] = useContext(User);
    const navigator = useNavigate();
    const isTeacher = useAdmin();

    const handleReportsClick = () => {
        if (isTeacher) {
            navigator('/zajecia')
            return;
        }

        navigator('/sprawozdania')
    }

    const handleLecturesClick = () => {
        navigator('/zajecia')
    }

    return (
        <>

            <div className="container mt-5">
                <h4><i className="bi bi-person-bounding-box mr-2"> </i>
                    Witaj<Name>{user?.data?.full_name ? ', ' + user.data.full_name + '!' : '!'}</Name>
                </h4>

                <Cards>
                    <Col md={6} xs={12} className="d-flex align-items-stretch">
                        <Card className="mt-3 pointer" onClick={handleReportsClick}>
                            <Card.Img variant="" src="1.jpg" alt="Przeglądaj sprawozdania"/>
                            <Card.Body>
                                <Card.Title>Sprawozdania</Card.Title>
                                <Card.Text>
                                    {isTeacher ? 'Przeglądaj sprawozdania studentów' : 'Dodaj lub przeglądaj swoje sprawozdania!'}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={6} xs={12} className="d-flex align-items-stretch">
                        <Card className="mt-3 pointer" onClick={handleLecturesClick}>
                            <Card.Img variant="" src="3.jpg" alt="Przeglądaj dostępne zajęcia"/>
                            <Card.Body>
                                <Card.Title>Zajęcia</Card.Title>
                                <Card.Text>
                                    {isTeacher ? 'Zarządzaj dostępnymi zajęciami' : 'Dołącz do grup zajęciowych!'}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Cards>
            </div>

            <div className="container mt-5">
                <h4><i className="bi bi-megaphone-fill"></i> Aktualności</h4>

                <div className="stat-card">
                    Masz 3 sprawozdania do wykonania.
                </div>

                <div className="content">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title"><i className="bi bi-calendar3"></i> 20 sierpnia 2024</h5>
                            <p className="card-text">Rozpoczęcie rekrutacji na semestr zimowy 2024/2025. Wszystkich
                                zainteresowanych prosimy o zapoznanie się z wymaganiami.</p>
                            <a href="#" className="btn btn-outline-primary"><i
                                className="bi bi-info-circle-fill"></i> Czytaj więcej</a>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title"><i className="bi bi-calendar3"></i> 15 sierpnia 2024</h5>
                            <p className="card-text">Zapisy na kursy dodatkowe już otwarte! Sprawdź naszą ofertę i
                                zdobądź nowe umiejętności.</p>
                            <a href="#" className="btn btn-outline-primary"><i
                                className="bi bi-info-circle-fill"></i> Czytaj więcej</a>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title"><i className="bi bi-calendar3"></i> 10 czerwiec 2024</h5>
                            <p className="card-text">Pragniemy poinformować, że w dniach 15-16 czerwca nastąpi przerwa
                                techniczna.</p>
                            <a href="#" className="btn btn-outline-primary"><i
                                className="bi bi-info-circle-fill disable"></i> Czytaj więcej</a>
                        </div>
                    </div>
                </div>
            </div>

            <footer className="footer">
                <p>&copy; 2024 Aktualności</p>
            </footer>

        </>
    )
}

export default Main