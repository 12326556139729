import React, {useContext, useEffect, useState} from 'react'
import {User} from '../../context/userContext'
import ReportRow from "./List/Row/ReportRow";

const Raports = () => {
    let [user] = useContext(User);
    let [lectures, setLectures] = useState([]);

    const fetchLectures = async () => {
        await fetch(`${process.env.REACT_APP_HOST}/api/student_summary/`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${user.token}`,
                'Content-Type': 'applicICOation/json',
            },
        })
            .then(res => res.json())
            .then(data => {
                setLectures(data);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const generateList = (lecture) => {
        if (lecture?.tasks.length) {
            return <ReportRow empty/>
        }

        return <ReportRow lecture={lecture}/>
    }

    useEffect(() => {
        if (user.logged) {
            fetchLectures();
        }

    }, [user.logged]);
    return (
        <>

            <div className="container mt-5">
                <div className="content">
                    <h4><i className="bi bi-book"></i> Sprawozdania</h4>

                    {lectures.length > 0 && lectures?.map((lecture, index) =>
                        <>
                            <h5>
                                <i className="bi bi-journal-bookmark-fillk"></i> {lecture?.lecture_name ?? ''}
                            </h5>
                            <div className="content table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                    <tr>
                                        <th>Temat:</th>
                                        <th>Prowadzący:</th>
                                        <th className='text-center'>Termin:</th>
                                        <th className='text-center'>Status:</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <ReportRow lecture={lecture}/>
                                    </tbody>
                                </table>
                            </div>
                        </>)}
                </div>
            </div>
        </>
    )
}

export default Raports